import React, { Fragment } from 'react'
import { NATIONAL_RESULTS } from '../../../lib/data/Awards'
import * as NationalResultsStyle from './NationalResults.module.scss'

interface NationalResultsProps {
  showWinners?: boolean
}

const NationalResults = ({ showWinners }: NationalResultsProps) => {
  return (
    <div>
      <table className={NationalResultsStyle.Table}>
        {NATIONAL_RESULTS.map((cat, i) => {
          const finalists = showWinners
            ? cat.finalists
            : [...cat.finalists, cat.winner]
          return (
            <Fragment key={i}>
              <tr>
                <td>
                  <h3>{cat.category}</h3>
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              {showWinners && (
                <>
                  <tr>
                    <td>
                      <strong>WINNER</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr
                    style={{
                      color: 'rgb(231, 44, 94)',
                      fontFamily: 'proxima_novasemibold'
                    }}
                  >
                    <td>
                      {cat.winner.name}, {cat.winner.town}
                    </td>
                    <td>{cat.winner.county}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </>
              )}
              <tr>
                <td>
                  <strong>FINALISTS</strong>
                </td>
                <td></td>
              </tr>
              {finalists
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((f, j) => (
                  <>
                    <tr>
                      <td>
                        {f.name}, {f.town}
                      </td>
                      <td>{f.county}</td>
                    </tr>
                  </>
                ))}
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </Fragment>
          )
        })}
      </table>
    </div>
  )
}

export default NationalResults

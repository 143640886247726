import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import { AdStarBlock, Category, GeneralSettings } from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import { AWARDS_COUNTY_INFO } from '../../lib/data/Awards'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
import { AwardCat } from './live-results'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../lib/utils'
import * as LeaderStyles from '../../components/Leader/Leader.module.scss'
import NationalResults from '../../components/MuddyAwards/NationalResults'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      awardsCategories {
        title
        image
        description
        id
        finalists {
          meta_a
          meta_b
          meta_c
          meta_d
          meta_e
        }
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsFAQProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      awardsCategories: AwardCat[]
      category: Category
    }
  }
}

const AwardsNationalResults = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsCategories,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
    }
  }
}: AwardsFAQProps) => {
  const AwardsContext = useContext(MuddyAwardsContext)
  const countyInfo = generalSettings.siteId
    ? AWARDS_COUNTY_INFO.filter(c => c.siteId === generalSettings.siteId)[0]
    : AWARDS_COUNTY_INFO[0]

  const titleText =
    AwardsContext === MuddyAwardsStates.NATIONAL_FINALISTS
      ? 'National Finalists'
      : 'National Winners'
  if (generalSettings.siteId !== 1) return null

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={`/ ${titleText}`}
            postURI={'/awards/national-results/'}
          />
          <h1
            dangerouslySetInnerHTML={{
              __html: titleText
            }}
          />
          {AwardsContext === MuddyAwardsStates.NATIONAL_FINALISTS ? (
            <>
              <p>
                Blimey that was tough! Choosing just 105 Muddy Stilettos
                National Awards finalists from the 399 utterly fabulous Regional
                Winners has been so difficult. Over the last few weeks, our
                expert editorial team has pitched, deliberated and arm wrestled,
                but we are finally in agreement. Meet the 5 brilliant finalists
                in each of the 21 categories across the 28 Muddy counties.
              </p>
              <p>
                They’re all incredible and worthy of a visit, so be sure to
                check them out and meet your local champions who have made it to
                the National stage.
              </p>
              <p>
                One winner per category will be crowned ‘Best of the Best’ on 11
                July 2024.
              </p>

              <NationalResults showWinners={false} />
            </>
          ) : (
            <>
              {AwardsContext === MuddyAwardsStates.NATIONAL_WINNERS ? (
                <>
                  <p>
                    <strong>
                      THE RESULTS ARE IN: MUDDY AWARDS 2024 NATIONAL WINNERS
                      ANNOUNCED
                    </strong>
                  </p>
                  <p>
                    Are you ready to meet the ‘Best of the Best?’ Across all 28
                    Muddy counties our on-the-ground editors and ed-in-chief,
                    Hero Brown, have selected one winner from each of the 21
                    lifestyle categories in our prestigious National Muddy
                    Awards.
                  </p>
                  <p>
                    All incredible and worthy of a visit – so take a look below
                    and meet your local champions.
                  </p>

                  <NationalResults showWinners />
                </>
              ) : (
                <>
                  <p>
                    Are you ready to meet the best of the best? All the
                    brilliant Regional Winners sashayed into Muddy Stilettos
                    National Awards. Over the last few weeks, our expert
                    editorial team has pitched, deliberated and arm wrestled to
                    bring you the Muddy Awards National Finalists. On 3 July,
                    you can meet the 5 brilliant finalists in each of the 21
                    categories across the Muddy counties.
                  </p>
                  <p>Stay tuned!</p>
                </>
              )}
            </>
          )}
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsNationalResults
